import React from 'react'
import { RxDoubleArrowRight } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

const HeadingText = ({title,redirect = true,className}) => {
    const navigate = useNavigate()
  return (
<>
{
  redirect ?  <div onClick={()=>navigate(`/category/${title}`)} className={`${className} inline-flex items-center gap-2 bg-red-600 px-6 py-3 text-white cursor-pointer`}>
  <h1 className='font-bold text-lg text-center flex-shrink-0'>{title}</h1>
  <RxDoubleArrowRight className='text-xl'/>
</div>
:
<div className='inline-flex items-center gap-2 bg-red-600 px-6 py-3 text-white '>
<h1 className='font-bold text-lg text-center flex-shrink-0'>{title}</h1>
<RxDoubleArrowRight className='text-xl'/>
</div>
}
</>
  )
}

export default HeadingText