import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const history = useNavigate();

  const handleGoHome = () => {
    history('/');
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-red-400 to-yellow-400 px-4">
      <div className="bg-white shadow-lg rounded-lg p-8 md:p-12 lg:max-w-md text-center flex flex-col items-center">
        <FiAlertTriangle className="text-yellow-500 text-6xl mb-4" />
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Oops! Something Went Wrong</h1>
        <p className="text-gray-600 mb-6">
          We're sorry, but it looks like there was an error. Please try again later or go back to the homepage.
        </p>
        <button
          onClick={handleGoHome}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
};
export default Error;
