import React from 'react'
import { FaFacebookF, FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import Error from './Error';
import { useGetAboutQuery } from '../features/api/aboutApi';
import { useGetFooterLinksQuery } from '../features/api/footerApi';
import Parser from '../Utils/Parser';
import { Link } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

const AboutUs = () => {

  const { data: about = [], error, isLoading: loading } = useGetAboutQuery();

  const {data: socialLinks, isLoading: socialLoading, error: socialError} = useGetFooterLinksQuery()

  if (loading || socialLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (error || socialError) {
    return <Error />;
  }


  return (
    <div className='w-full my-10 px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96'>
      <MetaTags title={'About Us - Kalerpotro.com'} description={'About us of Kalerpotro.com'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/about`}/>
      {
        about.map(about => (
          <>
          <h1 className='font-bold text-3xl text-red-600'>
        {about.headline}

        </h1>
        <hr className='w-full border border-gray-200 my-3'/>
        <p className='tracking-wider leading-8 mb-5'>
          <Parser text={about.details}/>
        </p>
          </>
        ))
      }
        <div className='mt-10 flex items-start flex-col gap-3'>
            <h1 className='font-bold text-3xl text-red-600'>অনুসরণ করুন</h1>
            <hr className='w-full border border-gray-200 my-3'/>
            <div className='flex items-center gap-3 flex-wrap'>
      {socialLinks
        .filter(social => social.column === 3)
        .sort((a, b) => a.order - b.order)
        .map(social => {
          if (social.name === 'Facebook') {
            return (
              <Link to={social.url} key="facebook">
              <button
                className='border border-blue-400 text-blue-600 rounded-md px-4 py-2 flex items-center gap-2'
              >
                <FaFacebookF />
                Facebook
              </button>
              </Link>
            );
          }

          if (social.name === 'Instagram') {
            return (
              <Link to={social.url} key='instagram'>
              <button
                className='border border-orange-400 text-orange-600 rounded-md px-4 py-2 flex items-center gap-2'
              >
                <FaInstagram />
                Instagram
              </button>
              </Link>
            );
          }

          if (social.name === 'Twitter') {
            return (
              <Link key="twitter" to={social.url}>
              <button
                
                className='border border-black text-black rounded-md px-4 py-2 flex items-center gap-2'
              >
                <FaXTwitter />
                Twitter
              </button>
              </Link>
            );
          }

          if (social.name === 'Youtube') {
            return (
              <Link to={social.url} key="youtube">
              <button
                
                className='border border-red-400 text-red-600 rounded-md px-4 py-2 flex items-center gap-2'
              >
                <FaYoutube />
                YouTube
              </button>
              </Link>
            );
          }

          return null;
        })
      }
    </div>
        </div>
    </div>
  )
}

export default AboutUs