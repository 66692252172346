import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const companyBannerApi = createApi({
  reducerPath: 'companyBannerApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getCompanyBanner: builder.query({
      query: () => '/api/ad/companybanner/list/',
    }),
  }),
});

export const { useGetCompanyBannerQuery } = companyBannerApi;
