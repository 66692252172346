import { configureStore } from '@reduxjs/toolkit';
import { aboutApi } from '../features/api/aboutApi';
import { adApi } from '../features/api/adApi';
import { bannerApi } from '../features/api/bannerApi';
import { categoriesApi } from '../features/api/categoriesApi';
import { companyBannerApi } from '../features/api/companyBannerApi';
import { contactApi } from '../features/api/contactApi';
import { footerApi } from '../features/api/footerApi';
import { logoApi } from '../features/api/logoApi';
import { navbarApi } from '../features/api/navbarApi';
import { newsApi } from '../features/api/newsApi';
import { trendingsApi } from '../features/api/trendingsApi';
import loadingSlice from '../features/loading/loadingSlice';

const store = configureStore({
  reducer: {
    [newsApi.reducerPath]: newsApi.reducer,
    [trendingsApi.reducerPath]: trendingsApi.reducer,
    [navbarApi.reducerPath]: navbarApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    loading: loadingSlice,
    [adApi.reducerPath]: adApi.reducer,
    [footerApi.reducerPath]: footerApi.reducer,
    [aboutApi.reducerPath]: aboutApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [logoApi.reducerPath]: logoApi.reducer,
    [companyBannerApi.reducerPath]: companyBannerApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(newsApi.middleware)
      .concat(trendingsApi.middleware)
      .concat(navbarApi.middleware)
      .concat(bannerApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(adApi.middleware)
      .concat(footerApi.middleware)
      .concat(aboutApi.middleware)
      .concat(contactApi.middleware)
      .concat(logoApi.middleware)
      .concat(companyBannerApi.middleware)
});

export default store;