import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const navbarApi = createApi({
  reducerPath: 'navbarApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getNavLinks: builder.query({
      query: () => '/api/ad/navbarlink/list/',
    }),
  }),
});

export const { useGetNavLinksQuery } = navbarApi;
