import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bannerApi = createApi({
  reducerPath: 'bannerApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => '/api/ad/banner/list/',
    }),
  }),
});

export const { useGetBannerQuery } = bannerApi;
