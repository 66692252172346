import React from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import HeadingText from "../components/HeadingText";
import { useGetAdQuery } from "../features/api/adApi";
import { useGetNewsQuery } from "../features/api/newsApi";
import useCategoryId from "../hooks/useCategoryId";
import Error from "../pages/Error";

const Section5 = ({ categories }) => {
  const navigate = useNavigate();
  const { data: news = [], error: newsError, isLoading: newsLoading } = useGetNewsQuery();
  const { categoriesId, categoriesLoading, categoriesError } = useCategoryId();
  const { data: ad, isLoading: adLoading, error: adError } = useGetAdQuery();
  const activeNews = news.filter(item => item.is_active);

  const renderNewsPosts = (posts, categoryName) => {
    if (posts.length === 0) return null;

    return (
      <>
        <div 
          className="relative my-4 cursor-pointer" 
          onClick={() => navigate(`/${categoryName}/news/${posts[0].id}`)}
        >
          <img 
            src={` ${posts[0].image}`} 
            alt={posts[0].headline} 
            className="w-full h-52 object-cover"
          />
          <div className='absolute inset-0 bg-black opacity-20 z-20'></div>
          <h1 className="absolute bottom-5 left-5 right-5 lg:text-base text-sm text-white font-bold z-30">
            {posts[0].headline}
          </h1>
        </div>
        {posts.slice(1, 4).map((post) => (
          <div 
            className="flex items-start gap-3 bg-white p-1 mb-5 cursor-pointer" 
            key={post.id} 
            onClick={() => navigate(`/${categoryName}/news/${post.id}`)}
          >
            <img 
              src={` ${post.image}`} 
              alt={post.headline} 
              className={`object-cover ${categories.length === 4 ? 'w-1/2 h-20' : 'w-2/5 h-24'}`} 
            />
            <div 
              className={`flex-1 text-${categories.length === 4 ? 'sm' : 'base'} overflow-hidden text-ellipsis`}
              style={{ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
            >
              {post.headline}
            </div>
          </div>
        ))}
      </>
    );
  };

  if (newsLoading || categoriesLoading || adLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError || categoriesError || adError) {
    return <Error />;
  }

  return (
    <div 
      className={`grid items-start gap-y-10 lg:gap-4 px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5] py-5 my-10 ${categories.length === 4 ? 'lg:grid-cols-4 grid-cols-1' : 'lg:grid-cols-[35%_35%_30%] grid-cols-1'}`}
    >
      {categories.map((categoryId) => {
        const categoryName = categoriesId[categoryId];
        
        if (!categoryName) {
          console.warn(`Category name not found for ID: ${categoryId}`);
          return null; 
        }

        const categoryPosts = activeNews.sort((a, b) => b.id - a.id).filter((post) => post.category === categoryId);

        return (
          <div className="p-2.5" key={categoryId}>
            <HeadingText title={categoryName} className={'mb-5'} />
            {renderNewsPosts(categoryPosts, categoryName)}
          </div>
        );
      })}

      {categories.length !== 4 && (
        <div className="p-2.5">
          <div className="mt-14 flex flex-col justify-between">
            {ad
              .filter((ad) => ad.page === 'HOME' && ad.section === '5')
              .sort((a, b) => a.order - b.order)
              .slice(0,2)
              .map((ad) => (
                <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
              ))}
            {ad
              .filter((ad) => ad.page === 'HOME' && ad.section === '7')
              .sort((a, b) => a.order - b.order)
              .slice(0,2)
              .map((ad) => (
                <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
              ))}
            {ad
              .filter((ad) => ad.page === 'HOME' && ad.section === '10')
              .sort((a, b) => a.order - b.order)
              .slice(0,2)
              .map((ad) => (
                <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Section5;