import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const aboutApi = createApi({
  reducerPath: 'aboutApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getAbout: builder.query({
      query: () => '/api/webinfo/about/list/',
    }),
  }),
});

export const { useGetAboutQuery } = aboutApi;
