export const getEmbedUrl = (url) => {
  // Check if the URL contains 'youtube.com' or 'youtu.be'
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    let videoId;

    // Extract video ID from 'youtube.com' URLs
    if (url.includes('youtube.com')) {
      const urlParams = new URLSearchParams(new URL(url).search);
      videoId = urlParams.get('v');
    }
    // Extract video ID from 'youtu.be' URLs
    else if (url.includes('youtu.be')) {
      videoId = url.split('/').pop();
    }

    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }
  }
  // Check if the URL contains 'facebook.com'
  else if (url.includes('facebook.com')) {
    let videoId;
    const urlParts = url.split('/');
    
    // Extract video ID from 'facebook.com' URLs
    if (urlParts.includes('videos')) {
      videoId = urlParts[urlParts.indexOf('videos') + 1];
    }
    
    if (videoId) {
      return `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/facebook/videos/${videoId}/`;
    }
  }
  
  return null;
};
