import { useEffect, useState } from 'react'
import { useGetCategoriesQuery } from '../features/api/categoriesApi';

const useCategoryId = () => {
    const [categoriesId, setCategoriesId] = useState({});
    const [categoriesNames, setCategoriesNames] = useState({})
    const { data: categories = [], error: categoriesError, isLoading: categoriesLoading } = useGetCategoriesQuery();
    
    useEffect(() => {
        if (categories.length > 0) {
          const ids = categories.reduce((acc, category) => {
            acc[category.id] = category.name;
            return acc;
          }, {});
          const names = categories.reduce((acc, category) => {
            acc[category.name] = category.id;
            return acc;
          }, {});
          setCategoriesId(ids);
          setCategoriesNames(names)
        }
      }, [categories]);
      

  return {categoriesId,categoriesLoading,categoriesError,categories,categoriesNames}
}

export default useCategoryId