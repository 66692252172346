import React from 'react'
import { useNavigate } from 'react-router-dom'
import { dateToTime } from '../Utils/dateToTime'
import Parser from '../Utils/Parser'

const NewsCard = ({news}) => {
  const navigate = useNavigate()

  return (
    <div
    onClick={() => navigate(`/${news.category_name}/news/${news.id}`)}
    key={news.id}
    className='flex items-start flex-col cursor-pointer bg-white p-1'
  >
    <img src={`${news.image}`} alt="news.png" className='w-full h-[200px] object-cover' />
    <div className='py-5 flex flex-col items-start gap-3 w-full'>
      <h1 className='text-xl font-bold tracking-wider'>{news.headline}</h1>
      <Parser text={news.content} card={true} textSize={100} className={'text-gray-600 text-sm font-secondary hover:text-black duration-500 font-medium'}/>
      <div className='w-full flex items-center justify-between mt-3 '>
        <span className='text-sm text-gray-400'>{dateToTime(news.created_at)}</span>
      </div>
    </div>
  </div>
  )
}

export default NewsCard