import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import MetaTags from "../components/MetaTags";
import { useGetBannerQuery } from "../features/api/bannerApi";
import { useGetCategoriesQuery } from "../features/api/categoriesApi";

import Section1 from "../sections/Section-1";
import Section3 from "../sections/Section-3";
import Section4 from "../sections/Section-4";
import Section5 from "../sections/Section-5";
import Section6 from "../sections/Section-6";
import Section7 from "../sections/Section-7";
import Section2 from './../sections/Section-2';
import Error from "./Error";

const Home = () => {
  const { data: banner, isLoading: bannerLoading, error: bannerError } = useGetBannerQuery();
  const { data: categories, isLoading: categoriesLoading, error: categoriesError } = useGetCategoriesQuery();

  const [sortedCategories, setSortedCategories] = useState([]);
  const [sortedBanners, setSortedBanners] = useState([]);

  useEffect(() => {
    if (categories) {
      const sorted = [...categories].sort((a, b) => a.order - b.order);
      setSortedCategories(sorted);
    }
  }, [categories]);

  useEffect(() => {
    if (banner) {
      const sorted = [...banner].filter(banner => banner.page === 'HOME').sort((a, b) => a.order - b.order);
      setSortedBanners(sorted);
    }
  }, [banner]);

  if (bannerLoading || categoriesLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (bannerError || categoriesError) {
    return <Error />;
  }

  const getCategoryByOrder = (order) => {
    return sortedCategories.find(category => category.order === parseInt(order)) || { id: 1 };
  };

  return (
    <>
    <MetaTags title={'KalerPotro | কালেরপত্র.কম | Bnagladeshi News'} description={'Stay updated with the latest news from Bangladesh with KalerPotro.com - কালেরপত্র.কম. Get breaking news, top headlines, and in-depth analysis on politics, business, technology, and more.'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}`}/>
      <Section1 />
      {getCategoryByOrder(1) && <Section2 category={getCategoryByOrder(1).id} />}
      {sortedBanners[0] && (
        <Banner key={sortedBanners[0].id} image={sortedBanners[0].image} link={sortedBanners[0].link} height={sortedBanners[0].height} width={sortedBanners[0].width} type={'image'} closable={true}/>
      )}
      {getCategoryByOrder(2) && <Section3 category={getCategoryByOrder(2).id} />}
      {getCategoryByOrder(3) && <Section4 category={getCategoryByOrder(3).id} />}
      {getCategoryByOrder(4) && getCategoryByOrder(5) && (
        <Section5 categories={[getCategoryByOrder(4).id, getCategoryByOrder(5).id]} />
      )}
      {sortedBanners[1] && (
        <Banner key={sortedBanners[1].id} image={sortedBanners[1].image} link={sortedBanners[1].link} height={sortedBanners[1].height} width={sortedBanners[1].width} type={'image'} closable={true}/>
      )}
      {getCategoryByOrder(6) && <Section4 category={getCategoryByOrder(6).id} />}
      {getCategoryByOrder(7) && getCategoryByOrder(8) && (
        <Section5 categories={[getCategoryByOrder(7).id, getCategoryByOrder(8).id]} />
      )}
      {getCategoryByOrder(9) && <Section6 category={getCategoryByOrder(9).id} />}
      {sortedBanners[2] && (
        <Banner key={sortedBanners[2].id} image={sortedBanners[2].image} link={sortedBanners[2].link} height={sortedBanners[2].height} width={sortedBanners[2].width} type={'image'} closable={true}/>
      )}
      {getCategoryByOrder(10) && <Section4 category={getCategoryByOrder(10).id} />}
      {getCategoryByOrder(11) && getCategoryByOrder(12) && getCategoryByOrder(13) && getCategoryByOrder(14) && (
        <Section5 categories={[getCategoryByOrder(11).id, getCategoryByOrder(12).id, getCategoryByOrder(13).id, getCategoryByOrder(14).id]} />
      )}
      <Section7 />
    </>
  );
};

export default Home;
