import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => '/api/news/category/list/',
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApi;

// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// export const categoriesApi = createApi({
//   reducerPath: 'categoriesApi',
//   baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
//   endpoints: (builder) => ({
//     getCategories: builder.query({
//       query: () => '/categories.json',
//     }),
//   }),
// });

// export const { useGetCategoriesQuery } = categoriesApi;
