import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoMdArrowDropright } from 'react-icons/io';
import Banner from '../components/Banner';
import MetaTags from '../components/MetaTags';
import NewsCard from '../components/NewsCard';
import { useGetAdQuery } from '../features/api/adApi';
import { useGetBannerQuery } from '../features/api/bannerApi';
import { useGetNewsQuery } from '../features/api/newsApi';
import Error from './Error';

const LatestNews = () => {
  const [todayDateNews, setTodayDateNews] = useState([]);
  const { data: news = [], error, isLoading } = useGetNewsQuery();
  const { data: banner, isLoading: bannerLoading, error: bannerError } = useGetBannerQuery();
  const { data: ads, isLoading: adsLoading, error: adsError } = useGetAdQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const numberOfPages = Math.ceil(todayDateNews.length / itemsPerPage);

  const pages = [...Array(numberOfPages).keys()];

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    if (!isLoading && news.length) {
      const today = new Date().toISOString().split('T')[0];
      const todayNews = news.filter(newsItem => newsItem.created_at.split('T')[0] === today);
      setTodayDateNews(todayNews);
      setCurrentPage(1);
    }
  }, [news, isLoading]);

  if (isLoading || bannerLoading || adsLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (error || bannerError || adsError) {
    return <Error />;
  }

  const displayedNews = todayDateNews.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className='mb-10 px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5] py-10'>
      <MetaTags title={'Latest News - Kalerpotro.com'} description={'Exploe latest news from today'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/latest`}/>
          <div className='w-full flex items-center justify-center my-5'>
    {banner
        .filter((ad) => ad.page === 'NEWS_POST')
        .sort((a, b) => a.order - b.order)
        .slice(1,2)
        .map((ad) => (
          <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={'image'} closable={true}/>
        ))}
    </div>
      <div className="flex items-center mb-10">
        <IoMdArrowDropright className="text-red-600 text-5xl" />
        <h1 className="text-3xl font-bold text-red-600">আজকের খবর</h1>
      </div>
      <div className='w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 row-auto items-center gap-5'>
        {
          displayedNews && displayedNews.length > 0 ? displayedNews.map(news => (
            <NewsCard news={news}/>
          ))
          : 
          <div className="flex items-center justify-center h-full w-full">
          <div className="bg-gray-100 rounded-lg p-8">
            <p className="text-xl text-gray-500">No results found</p>
          </div>
        </div>
        }
      </div>
      {ads
        .filter((ad) => ad.page === 'NEWS_POST')
        .sort((a, b) => a.order - b.order)
        .slice(0,1)
        .map((ad) => (
          <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
        ))}
      {todayDateNews.length > 0 && (
        <div className="flex items-center gap-5 py-10 lg:px-20 px-5 w-full justify-center">
          <button
            disabled={currentPage < 2}
            onClick={handlePrevPage}
            className={`bg-[#E7F0FA] flex items-center justify-center h-10 w-10 rounded-full text-2xl ${
              currentPage < 2 ? 'text-[#99C2FF]' : 'text-primary'
            }`}
          >
            <IoIosArrowBack />
          </button>
          {pages.map((page) => (
            <button
              onClick={() => setCurrentPage(page + 1)}
              key={page + 1}
              className={`${
                currentPage === page + 1
                  ? 'bg-gray-400 text-white'
                  : 'bg-[#F1F2F4] text-[#5E6670]'
              } flex items-center justify-center h-10 w-10 rounded-full text-lg font-medium`}
            >
              {page + 1}
            </button>
          ))}
          <button
            disabled={currentPage === pages.length}
            onClick={handleNextPage}
            className={`bg-[#E7F0FA] flex items-center justify-center h-10 w-10 rounded-full text-2xl ${
              currentPage === pages.length ? 'text-[#99C2FF]' : 'text-primary'
            }`}
          >
            <IoIosArrowForward />
          </button>
        </div>
      )}
    </div>
  )
}

export default LatestNews