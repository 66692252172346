import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import HeadingText from "../components/HeadingText";
import NewsCard from "../components/NewsCard";
import { useGetAdQuery } from "../features/api/adApi";
import { useGetNewsQuery } from "../features/api/newsApi";
import useCategoryId from "../hooks/useCategoryId";
import Error from "../pages/Error";

const Section2 = ({ category }) => {
  const navigate = useNavigate();
  const { data: ad, isLoading: adLoading, error: adError } = useGetAdQuery();
  const {
    data: news = [],
    error: newsError,
    isLoading: newsLoading,
  } = useGetNewsQuery();
  const [filterNews, setFilterNews] = useState([]);
  const { categories } = useCategoryId();
  const [singleCategory, setSingleCategory] = useState(null);

  useEffect(() => {
    const activeNews = news.filter((item) => item.is_active);
    const filteredNews = activeNews
      .sort((a, b) => b.id - a.id)
      .filter((newsItem) => newsItem.category === parseInt(category));
    const singleCat = categories.find((cat) => cat.id === parseInt(category));
    setSingleCategory(singleCat);
    setFilterNews(filteredNews);
  }, [news, category, categories]);

  if (newsLoading || adLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError || adError) {
    return <Error />;
  }

  return (
    <div className="w-full py-5 my-10 px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5]">
      <div className="w-full flex items-center justify-start">
        <HeadingText title={singleCategory?.name} />
      </div>
      <div className=" flex lg:flex-row flex-col lg:gap-8 gap-y-20 mt-10">
        <div className="lg:w-[25%] md:w-full w-full flex flex-col justify-start items-center md:items-start lg:gap-4 gap-8">
          {filterNews.slice(0, 7).map((card) => (
            <div
              key={card.id}
              onClick={() => navigate(`/${card.category_name}/news/${card.id}`)}
              className="flex flex-col items-start gap-3 w-full cursor-pointer bg-white p-1"
            >
              <div className="flex items-start w-full gap-4">
                <img
                  src={`${card.image}`}
                  alt={card.headline}
                  className="w-[250px] h-[80px] object-cover rounded-md"
                />
                <h3 className="lg:text-sm text-sm md:text-sm w-full font-medium">
                  {card.headline}
                </h3>
              </div>
              {/* <Parser text={card.content} card={true} textSize={80} className={'text-gray-700 lg:text-xs text-xs hover:text-black duration-500 font-normal'}/> */}
            </div>
          ))}
        </div>
        <div className="lg:w-[50%] w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 row-auto items-stretch lg:items-start gap-x-4 gap-y-4">
          {filterNews.slice(8, 12).map((card) => (
            <NewsCard news={card} />
          ))}
        </div>
        <div className="lg:w-[25%] w-full md:w-full">
          {ad
            .filter(
              (adItem) => adItem.page === "HOME" && adItem.section === "2"
            )
            .sort((a, b) => a.order - b.order)
            .map((ad) => (
              <Banner
                key={ad.id}
                image={ad.image}
                link={ad.link}
                height={ad.height}
                width={ad.width}
                type={ad.file_type}
                video={ad.video}
                closable={true}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Section2;
