import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import HeadingText from "../components/HeadingText";
import LocationSearch from "../components/LocationSearch";
import { useGetAdQuery } from "../features/api/adApi";
import { useGetNewsQuery } from "../features/api/newsApi";
import useCategoryId from "../hooks/useCategoryId";
import Error from "../pages/Error";
import { dateToTime } from "../Utils/dateToTime";
import Parser from "../Utils/Parser";

const Section6 = () => {
  const navigate = useNavigate();
  const { data: news = [], error: newsError, isLoading: newsLoading } = useGetNewsQuery();
  const { categoriesId, categoriesLoading, categoriesError } = useCategoryId();
  const [filterNews, setFilterNews] = useState([]);
  const { data: ad, isLoading: adLoading, error: adError } = useGetAdQuery();

  useEffect(() => {
    const activeNews = news.filter((item) => item.is_active);
    const filteredNews = activeNews
      .sort((a, b) => b.id - a.id)
      .filter((news) => news.category_name === "সারাদেশ");
    setFilterNews(filteredNews);
  }, [news]);

  if (newsLoading || categoriesLoading || adLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError || categoriesError || adError) {
    return <Error />;
  }

  const getCategoryNameById = (id) => {
    return categoriesId[id] || "unknown";
  };

  return (
    <div className="w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96">
      <HeadingText title={"সারাদেশ"} />
      <LocationSearch />
      <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-8 bg-gray-100 py-5 my-10 px-5">
        {filterNews.slice(0, 1).map((news) => (
          <div
            key={news.id}
            onClick={() => navigate(`/${getCategoryNameById(news.category)}/news/${news.id}`)}
            className="cursor-pointer"
          >
            <img
              src={`${news.image}`}
              alt="Main News"
              className="w-full rounded-lg object-cover"
            />
            <div className="flex gap-2 mt-5 mb-4 cursor-pointer">
              <div>
                <h1 className="font-bold text-blue-600 text-xl">{news.headline}</h1>
                <Parser text={news.content} card={true} textSize={100} className={'text-gray-700 lg:text-sm mt-2 text-sm font-secondary hover:text-black duration-500 font-medium'}/>
                <span className="text-sm text-gray-400">{dateToTime(news.created_at)}</span>
              </div>
            </div>
          </div>
        ))}
        <div className="w-full">
          {filterNews.slice(1,4).map((news) => (
            <div
              key={news.id}
              onClick={() => navigate(`/${getCategoryNameById(news.category)}/news/${news.id}`)}
              className="flex items-start justify-between gap-3 mb-4 cursor-pointer"
            >
              <div className="flex flex-col items-start gap-2">
                <h1 className="text-blue-500 font-bold text-lg">{news.headline}</h1>
                <Parser
                  text={news.content}
                  card={true}
                  textSize={60}
                  className="text-gray-600 text-sm hover:text-black duration-500 font-medium"
                />
                <span className="text-sm text-gray-400">{dateToTime(news.created_at)}</span>
              </div>
              <img
                src={news.image}
                alt="news.image"
                className="w-1/2 h-24 rounded-md object-cover"
              />
            </div>
          ))}
        </div>
        <div className="w-full">
          {filterNews.slice(4, 6).map((news) => (
            <div
              key={news.id}
              onClick={() => navigate(`/${getCategoryNameById(news.category)}/news/${news.id}`)}
              className="flex items-start justify-between gap-3 mb-4 cursor-pointer"
            >
              <div className="flex flex-col items-start gap-2">
                <h1 className="text-blue-600 font-bold text-lg">{news.headline}</h1>
                <Parser
                  text={news.content}
                  card={true}
                  textSize={60}
                  className="text-gray-600 text-sm hover:text-black duration-500 font-medium"
                />
                <span className="text-sm text-gray-400">{dateToTime(news.created_at)}</span>
              </div>
              <img
                src={news.image}
                alt="news.image"
                className="w-1/2 h-24 rounded-md object-cover"
              />
            </div>
          ))}
          {ad
            .filter((ad) => ad.page === "HOME" && ad.section === "8")
            .sort((a, b) => a.order - b.order)
            .slice(0, 1)
            .map((ad) => (
              <Banner
                key={ad.id}
                image={ad.image}
                link={ad.link}
                height={ad.height}
                width={ad.width}
                type={ad.file_type}
                closable={true}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Section6;
