import { formatDistanceToNow, parseISO } from 'date-fns';
import { bn } from 'date-fns/locale';

 export function dateToTime(timestamp) {
  const date = parseISO(timestamp);
  const timeDifference = formatDistanceToNow(date, { addPreffix: false,addSuffix: true, locale: bn });

  // Custom translation for 'ago'
  return timeDifference.replace('ago', 'আগে');
}
