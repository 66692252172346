import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const footerApi = createApi({
  reducerPath: 'footerApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getFooterLinks: builder.query({
      query: () => '/api/ad/footerlinks/list/',
    }),
  }),
});

export const { useGetFooterLinksQuery } = footerApi;
