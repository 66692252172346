import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Parser = ({ text, className = '', card = true, textSize }) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    const sanitizedHtml = DOMPurify.sanitize(text);

    if (card) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = sanitizedHtml;
      const plainText = tempElement.textContent || tempElement.innerText || '';
      

      const slicedHtml = DOMPurify.sanitize(plainText.slice(0, parseInt(textSize)) + '...');
      setDescription(slicedHtml);
    } else {
      setDescription(sanitizedHtml);
    }
  }, [text, card,textSize]);

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: description }} />
  );
};


Parser.propTypes = {
  text: PropTypes.string.isRequired, 
  className: PropTypes.string,
  card: PropTypes.bool,
  textSize: PropTypes.number
};


Parser.defaultProps = {
  className: '',
  card: true,
  textSize: 80
};

export default Parser;
