import PropTypes from "prop-types";
import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";

const Banner = ({ image, link, height, width, type, video, closable }) => {
  const [hideBanner, setHideBanner] = useState(false)
  return (
    <div
      className={`w-full justify-center items-center my-5 mx-auto overflow-hidden border border-gray-30 relative ${hideBanner ? 'hidden' : 'flex'}`}
      style={{ maxWidth: `${width}px`, height: `${height}px` }}
    >
    {
      closable && <button onClick={()=>setHideBanner(true)} className="absolute top-0 -right-0 text-red-600 text-2xl rounded-full">
        <IoCloseCircle />
      </button>
    }
      <a href={link || "#"} className="w-full h-full">
        {type?.toLowerCase() === "image" && (
          <img
            src={image}
            alt="Banner"
            className="w-full h-full object-cover shadow-md"
          />
        )}
        {type?.toLowerCase() === "video" && (
          <video controls className="w-full h-full object-cover shadow-md">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </a>
    </div>
  );
};

Banner.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["image", "video"]),
  video: PropTypes.string,
  closable: PropTypes.bool,
};

// Adding default props
Banner.defaultProps = {
  link: "#",
  height: 300,
  width: 600,
  type: "image",
  closable: false
};

export default Banner;
