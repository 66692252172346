import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getContact: builder.query({
      query: () => '/api/webinfo/contact/list/',
    }),
  }),
});

export const { useGetContactQuery } = contactApi;
