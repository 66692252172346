export const shareOnFacebook = (url, quote, media) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(quote)}&picture=${encodeURIComponent(media)}`;
    window.open(facebookShareUrl, '_blank', 'width=600,height=400');
  };
  
export  const shareOnWhatsApp = (url, quote) => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(quote)}%20${encodeURIComponent(url)}`;
    window.open(whatsappShareUrl, '_blank');
  };
  
 export const shareOnTwitter = (url, quote) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(quote)}&url=${encodeURIComponent(url)}`;
    window.open(twitterShareUrl, '_blank', 'width=600,height=400');
  };
  
export  const shareOnLinkedIn = (url, quote) => {
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(quote)}`;
    window.open(linkedInShareUrl, '_blank', 'width=600,height=400');
  };
  
  