import React, { memo, Suspense, useEffect, useMemo, useState } from 'react';
import { FaPlay, FaUserEdit } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';
import { IoTimeOutline } from 'react-icons/io5';
import { RxDoubleArrowRight } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import { dateToTime } from '../Utils/dateToTime';
import { getEmbedUrl } from '../Utils/getEmbedUrl';
import Banner from '../components/Banner';
import MetaTags from '../components/MetaTags';
import NewsShareButton from '../components/NewsShareButton';
import { useGetAdQuery } from '../features/api/adApi';
import { useGetBannerQuery } from '../features/api/bannerApi';
import { useGetNewsQuery } from '../features/api/newsApi';
import Error from './Error';

const VideoDetails = () => {
  const navigate = useNavigate();
  const { category, id } = useParams();
  const { data: banner, isLoading: bannerLoading, error: bannerError } = useGetBannerQuery();
  const { data: ads, isLoading: adsLoading, error: adsError } = useGetAdQuery();
  const { data: news = [], error:newsError, isLoading: newsLoading } = useGetNewsQuery();
  const [singleNews, setSingleNews] = useState([]);
  const [categoryNews, setCategoryNews] = useState([])
  const activeNews = useMemo(() => news.filter(item => item.is_active), [news]);

  const [shareCountSortNews, setShareCountSortNews] = useState([])
  const [dateSortedNews, setDateSortedNews] = useState([])

  useEffect(() => {
    if (activeNews.length > 0) {
      const foundNews = activeNews.find((newsItem) => newsItem.id === parseInt(id));
      const sorted = [...activeNews].sort((a, b) => b.share_count - a.share_count);
      const filteredNews = activeNews.filter((newsItem) => newsItem.category_name === category);
      const dateSorted = [...activeNews].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setDateSortedNews(dateSorted);
      setCategoryNews(filteredNews);
      setSingleNews(foundNews);
      setShareCountSortNews(sorted);
    }
  }, [activeNews, id, category]);
  

  if (newsLoading || !singleNews || bannerLoading || adsLoading || !singleNews || !shareCountSortNews) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }


  if (newsError || bannerError || adsError) {
    return <Error />;
  }

  return (
    <div className="w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 py-10 bg-[#F5F5F5]">
      <MetaTags
        title={singleNews.headline}
        description={singleNews.content.slice(0, 200)}
        image={singleNews.image}
        url={`${process.env.REACT_APP_CLIENT_URL}/${category}/news/${id}`}
      />
      <div className="w-full grid lg:grid-cols-[70%_25%] grid-cols-1 row-auto items-start gap-[2%]">
        <div className="w-full flex flex-col items-start">
          <div className="w-full bg-white border border-gray-200 rounded-md px-5 py-2 flex items-center justify-start gap-2 text-gray-600 mb-3">
            <HiHome />
            <span>হোম</span>
            <RxDoubleArrowRight />
            <span>{category}</span>
          </div>

          <div className="w-full flex items-start gap-3 flex-col">
            <h1 className="text-3xl font-bold tracking-wider">{singleNews.headline}</h1>
            <hr className="w-full border border-gray-200 my-2" />
            <div className="w-full gap-2 lg:flex-row md:flex-row flex flex-col lg:items-center items-start md:items-center justify-between">
              <div className="w-full">
                <div className="flex items-center gap-2">
                  <FaUserEdit className="text-blue-600 text-lg" />
                  <span className="text-blue-600">{singleNews.editor_name}</span>
                  <span className="text-blue-300">||</span>
                  <span className="italic text-gray-500"> কালেরপত্র.কম</span>
                </div>
                <div className="flex items-center gap-3 text-gray-500 text-sm">
                  <IoTimeOutline className="text-red-600 text-lg" />
                  <span>প্রকাশিত:</span>
                  <span>{dateToTime(singleNews.created_at)}</span>
                </div>
              </div>
              <NewsShareButton news={singleNews} catName={category} iconName={false} type={'video'} />
            </div>
            {ads
              .filter((ad) => ad.page === 'NEWS_POST')
              .sort((a, b) => a.order - b.order)
              .slice(0, 1)
              .map((ad) => (
                <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.type} closable={true} />
              ))}
            <div className="w-full lg:h-[500px] h-[300px] bg-white p-3">
              <iframe
                width="560"
                height="500"
                src={getEmbedUrl(singleNews.video)}
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
          {banner
            .filter((ad) => ad.page === 'NEWS_POST')
            .sort((a, b) => a.order - b.order)
            .slice(2, 3)
            .map((ad) => (
              <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true} />
            ))}
        </div>
        <div className="w-full">
          <div className="w-full bg-white p-2">
            <div className="bg-red-600 text-white font-bold text-lg py-3 rounded-md flex items-center justify-center">
              সর্বশেষ
            </div>
            <div className="w-full grid grid-cols-1 row-auto items-center h-[300px] overflow-y-auto gap-y-3 scroll mt-5">
              {dateSortedNews.slice(0, 5).map((newsItem) => (
                <div
                  onClick={() => navigate(`/${category}/news/${newsItem.id}`)}
                  key={newsItem.id}
                  className="w-full flex items-start gap-3 cursor-pointer p-1 border border-gray-300 rounded-md"
                >
                  <img
                    src={newsItem.image}
                    alt="news.png"
                    loading="lazy"
                    className="w-[120px] object-cover h-[80px] overflow-hidden rounded-md"
                  />
                  <h1 className="text-sm font-medium w-full">{newsItem.headline}</h1>
                </div>
              ))}
            </div>
          </div>
          <Suspense fallback='<h1>Loading...</h1>'>
          <div className="w-full mt-5 bg-white p-2">
            <div className="bg-red-600 text-white font-bold text-lg py-3 rounded-md flex items-center justify-center">
              পাঠকপ্রিয়
            </div>
            <div className="w-full grid grid-cols-1 row-auto items-center h-[300px] overflow-y-auto gap-y-3 scroll mt-5">
              {shareCountSortNews.slice(0, 5).map((newsItem) => (
                <div
                  onClick={() => navigate(`/${category}/news/${newsItem.id}`)}
                  key={newsItem.id}
                  className="w-full flex items-start gap-3 cursor-pointer p-1 border border-gray-300 rounded-md"
                >
                  <img
                    src={newsItem.image}
                    alt="news.png"
                    loading="lazy"
                    className="w-[120px] object-cover h-[80px] overflow-hidden rounded-md"
                  />
                  <h1 className="text-sm font-medium w-full">{newsItem.headline}</h1>
                </div>
              ))}
            </div>
          </div>
          </Suspense>
        </div>
      </div>
      <div className="w-full lg:mt-10 mt-40 bg-white p-3">
        <div className="bg-red-600 px-4 py-3 text-white font-bold text-center inline-flex items-center gap-2">
          <h1>আরও দেখুন</h1>
          <RxDoubleArrowRight className="text-xl" />
        </div>
        <div className="mt-10 w-full grid lg:grid-cols-4 grid-cols-1 row-auto items-stretch gap-5">
          {categoryNews.slice(0, 12).map((newsItem) => (
            <div
              onClick={() => navigate(`/${category}/video/${newsItem.id}`)}
              key={newsItem.id}
              className="w-full h-[250px] relative bg-black flex items-end justify-center overflow-hidden rounded-md border border-gray-300 p-2"
            >
              <iframe
                width="560"
                height="500"
                src={getEmbedUrl(newsItem.video)}
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
              <div
                className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center cursor-pointer"
                onClick={() => navigate(`/${category}/video/${newsItem.id}`)}
              >
                <FaPlay className="text-white text-6xl" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(VideoDetails);
