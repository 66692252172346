import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { onMessage, requestPermission } from '../config/firebase.config';
import { useGetNewsQuery } from '../features/api/newsApi';
import isMobileDevice from '../Utils/isMobileDevice';
import useCategoryId from './useCategoryId';

const usePollNews = () => {
  const [latestNews, setLatestNews] = useState([]);
  const [notifiedNewsIds, setNotifiedNewsIds] = useState(new Set());
  const { data: news = [], error, isLoading } = useGetNewsQuery();
  const { categoriesId, categoriesLoading } = useCategoryId();

  useEffect(() => {
    const notifiedIdsFromStorage = localStorage.getItem('notifiedNewsIds');
    if (notifiedIdsFromStorage) {
      setNotifiedNewsIds(new Set(JSON.parse(notifiedIdsFromStorage)));
    }
  }, []);

  useEffect(() => {
    if (!isMobileDevice()) {
      requestPermission();
      onMessage((payload) => {
        console.log('Message received. ', payload);
      });
    }
  }, []);

  useEffect(() => {
    if (isMobileDevice() || !news.length || categoriesLoading) return;

    const lastNewsIdFromStorage = localStorage.getItem('lastNewsId');
    const lastNewsId = lastNewsIdFromStorage ? parseInt(lastNewsIdFromStorage, 10) : null;

    if (lastNewsId === null) {
      localStorage.setItem('lastNewsId', news[0]?.id);
      setLatestNews(news);
      return;
    }

    const newNews = [...news] 
    .sort((a, b) => b.id - a.id)
    .filter(
      (newsItem) => newsItem.id > lastNewsId && !notifiedNewsIds.has(newsItem.id)
    );

    if (newNews.length) {
      localStorage.setItem('lastNewsId', news[0]?.id);
      setLatestNews(news);
      
      newNews.forEach(newsItem => {
        const plainText = sanitizeAndSlice(newsItem.content, 200);
        const notification = new Notification(newsItem.headline, {
          body: plainText,
          icon: newsItem.image,
          data: { url: `/${categoriesId[newsItem.category]}/news/${newsItem.id}` } 
        });

        notification.onclick = (event) => {
          event.preventDefault();
          window.open(notification.data.url, '_blank');
        };

        setNotifiedNewsIds(prevState => {
          const updatedSet = new Set(prevState);
          updatedSet.add(newsItem.id);
          localStorage.setItem('notifiedNewsIds', JSON.stringify(Array.from(updatedSet)));
          return updatedSet;
        });
      });
    }
  }, [news, notifiedNewsIds, categoriesId, categoriesLoading]);

  if (categoriesLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  return { latestNews, error, isLoading };
};

const sanitizeAndSlice = (text, size) => {
  const sanitizedHtml = DOMPurify.sanitize(text);
  const tempElement = document.createElement('div');
  tempElement.innerHTML = sanitizedHtml;
  const plainText = tempElement.textContent || tempElement.innerText || '';
  return plainText.slice(0, size) + '...';
};

export default usePollNews;
