import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const trendingsApi = createApi({
  reducerPath: 'trendingsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getTrendings: builder.query({
      query: () => '/api/ad/trendingtags/list/',
    }),
  }),
});

export const { useGetTrendingsQuery } = trendingsApi;