import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import HeadingText from '../components/HeadingText';
import { useGetNewsQuery } from '../features/api/newsApi';
import useCategoryId from '../hooks/useCategoryId';
import Error from '../pages/Error';

const Section4 = ({ category }) => {
  const navigate = useNavigate();
  const { data: news = [], error: newsError, isLoading: newsLoading } = useGetNewsQuery();
  const {categoriesLoading,categoriesError,categories} = useCategoryId()
  const [singleCategory, setSingleCategory] = useState(null);
  const [filterNews, setFilterNews] = useState([]);


  useEffect(() => {
    const activeNews = news.filter(item => item.is_active);
    const filteredNews = activeNews.sort((a, b) => b.id - a.id).filter((newsItem) => newsItem.category === parseInt(category));
    setFilterNews(filteredNews);

    const singleCat = categories.find((cat) => cat.id === parseInt(category));
    setSingleCategory(singleCat);
  }, [category, categories, news]);

  if (newsLoading || categoriesLoading || !singleCategory) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError || categoriesError) {
    return <Error />;
  }

  return (
    <div className=' w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5] py-5 my-10'>
      <div className='w-full flex items-center justify-start'>
        <HeadingText title={singleCategory.name} />
      </div>
      <div className='w-full mt-10'>
        <Swiper
          modules={[Autoplay]}
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{ delay: 2000 }}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {filterNews.map((newsItem) => (
            <SwiperSlide key={newsItem.id} onClick={() => navigate(`/${newsItem.category_name}/news/${newsItem.id}`)}>
              <div className='w-full h-[300px] 2xl:h-[500px] relative rounded-md overflow-hidden cursor-pointer '>
                <img
                  src={`${newsItem.image}`}
                  alt="news"
                  className='w-full h-full object-cover z-10'
                />
                <div className='absolute inset-0 bg-black opacity-30 z-20'></div>
                <h1 className='lg:text-xl text-sm font-bold absolute bottom-5 left-5 z-30 text-white'>
                  {newsItem.headline}
                </h1>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Section4;