import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  // baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  tagTypes: ['News'], 
  endpoints: (builder) => ({
    getNews: builder.query({
      // query: () => '/news2.json',
      query: () => '/api/news/list',
      providesTags: ['News']
    }),
    updateShareCount: builder.mutation({
      query: ({ newsId, share_count }) => ({
        url: `/api/news/${newsId}/`,
        method: 'PATCH',
        body: { share_count },
      }),
      invalidatesTags: ['News'],
    }),
  }),
});

export const { useGetNewsQuery, useUpdateShareCountMutation } = newsApi;