import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import RootLayout from '../layouts/RootLayout';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import Error from '../pages/Error';
import Home from '../pages/Home';
import LatestNews from '../pages/LatestNews';
import NewsByCatOrTrenOrSearch from '../pages/NewsByCatOrTrenOrSearch';
import NewsDetails from '../pages/NewsDetails';
import VideoDetails from '../pages/VideoDetails';

const Routes = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout/>,
      children: [
        {
          path: '',
          element: <Home/>
        },
        {
            path: "/search/:query",
            element: <NewsByCatOrTrenOrSearch/>
        },
        {
          path: "/tag/:tag",
          element: <NewsByCatOrTrenOrSearch/>
      },
      {
        path: "/category/:category",
        element: <NewsByCatOrTrenOrSearch/>
      },
      {
        path: "/:category/news/:id",
        element: <NewsDetails/>
      },
      {
        path: "/bangladesh/division_news/:division",
        element: <NewsByCatOrTrenOrSearch/>
      },
      {
        path: "/bangladesh/district_news/:division/:district",
        element: <NewsByCatOrTrenOrSearch/>
      },
      {
        path: "/bangladesh/upazila_news/:division/:district/:upazila",
        element: <NewsByCatOrTrenOrSearch/>
      },
      {
        path: "/about",
        element: <AboutUs/>
      },
      {
        path: "/contact",
        element: <ContactUs/>
      },
      {
        path: "/:category/video/:id",
        element: <VideoDetails/>
      },
      {
        path: '/latest',
        element: <LatestNews/>
      }
      ],
      errorElement: <Error/>
    },
  ]);

export default Routes