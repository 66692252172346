import { useEffect, useState } from 'react';
import { useGetNewsQuery } from '../features/api/newsApi';
import useCategoryId from './useCategoryId';

const useSortedNews = ({ category = null, id = null, query = null }) => {
  const { data: news = [], error, isLoading } = useGetNewsQuery();
  const activeNews = news.filter(item => item.is_active);
  const [sortedNews, setSortedNews] = useState([]);
  const [catSort, setCatSort] = useState([]);
  const [singleNews, setSingleNews] = useState(null);
  const { categoriesNames, categoriesId } = useCategoryId();
  const [searchedNews, setSearchedNews] = useState([]);
  const [dateSortedNews, setDateSortedNews] = useState([]);

  useEffect(() => {
    if (!isLoading && activeNews.length) {
      const sorted = [...activeNews].sort((a, b) => b.share_count - a.share_count);
      setSortedNews(sorted);
    }
  }, [news, isLoading,activeNews]);

  useEffect(() => {
    if (!isLoading && category && activeNews.length) {
      const filteredNews = activeNews.filter((newsItem) => newsItem.category_name === category);
      setCatSort(filteredNews);
    }
  }, [news, category, categoriesNames, isLoading,activeNews]);

  useEffect(() => {
    if (!isLoading && id && activeNews.length) {
      const idParsed = parseInt(id, 10);
      const foundNews = activeNews.find((newsItem) => newsItem.id === idParsed);
      setSingleNews(foundNews || null);
    }
  }, [news, id, isLoading,activeNews]);

  useEffect(() => {
    if (!isLoading && query && activeNews.length) {
      const queryWords = query.toLowerCase().split(' ');
      setSearchedNews(activeNews.filter((newsItem) =>
        queryWords.some((word) =>
          newsItem.headline.toLowerCase().includes(word) || 
          newsItem.content.toLowerCase().includes(word)
        )
      ));
    }
  }, [news, query, isLoading,activeNews]);

  useEffect(() => {
    if (!isLoading && activeNews.length) {
      const dateSorted = [...activeNews].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setDateSortedNews(dateSorted);
    }
  }, [news, isLoading,activeNews]);

  return { 
    shareCountSortNews: sortedNews, 
    error, 
    isLoading, 
    news, 
    categoryNews: catSort, 
    singleNews,
    searchedNews,
    dateSortedNews,
    categoriesId
  };
};

export default useSortedNews;
