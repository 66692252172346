import React from 'react';
import HeadingText from '../components/HeadingText';
import NewsCard from '../components/NewsCard';
import { useGetNewsQuery } from '../features/api/newsApi';
import Error from '../pages/Error';

const Section3 = () => {
  const { data: news = [], error: newsError, isLoading: newsLoading } = useGetNewsQuery();


  if (newsLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError) {
    return <Error />;
  }
  const activeNews = [...news] 
    .sort((a, b) => b.id - a.id)
    .filter(item => item.is_active);

  return (
    <div className='py-5 my-10 bg-[#F5F5F5] w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96'>
      <HeadingText title={'আপনার জন্য'} redirect={false}/>
      <div className='w-full mt-10 grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-4 2xl:gap-4 2xl:gap-y-16 items-stretch'>
        {activeNews.slice(1, 9).map((newsItem) => (
          <NewsCard news={newsItem}/>
        ))}
      </div>
    </div>
  );
};

export default Section3;