import React from 'react';
import Error from './Error';
import { useGetContactQuery } from '../features/api/contactApi';
import MetaTags from '../components/MetaTags';

const ContactUs = () => {

  const { data: contact = [], error, isLoading: loading } = useGetContactQuery();


  if (loading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (error) {
    return <Error />;
  }


  return (
    <div className="w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 my-10">
      <MetaTags title={'Contact Us - Kalerpotro.com'} description={'Contact us of Kalerpotro.com'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/latest`}/>
      <h1 className='font-bold text-3xl text-red-600'>
      যোগাযোগ
        </h1>
        <hr className='w-full border border-gray-200 my-5'/>
        <div className='w-full grid lg:grid-cols-2 grid-cols-1 row-auto items-start gap-10'>
        {
          contact.map(contact => (
            
          <div className='flex flex-col items-start gap-3'>
           <div>
           <h1 className='font-bold text-xl text-red-600'>
            প্রধান কার্যালয়
            </h1>
            <p>{contact.address}</p>
           </div>
    <div>
    <h1 className='font-bold text-xl text-red-600'>
    ফোন
            </h1>
            <p><a href={`tel:${contact.phone}`}>{contact.phone}</a></p>
    </div>
    <div>
           <h1 className='font-bold text-xl text-red-600'>
           ইমেইল
            </h1>
            <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
           </div>
          </div>
          ))
        }
        </div>
    </div>
  );
}

export default ContactUs;
