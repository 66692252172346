import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../features/loading/loadingSlice'
import usePollNews from '../hooks/usePollNews'

const RootLayout = () => {
const dispatch = useDispatch()
const loading = useSelector(state => state.loading.loading)
const { isLoading } = usePollNews();

useEffect(()=>{
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 1000);
},[dispatch])

if(loading || isLoading){
    return <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
    <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
    <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
    <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
  </div>
}

  return (
    <div className='font-primary'>
    <Navbar/>
    <div className='w-full lg:min-w-[1200px] xl:min-w-[1200px] 2xl:min-w-[1200px] 4k:min-w-[1200px]'>

    <Outlet/>
    </div>
    <Footer/>
    </div>
  )
}

export default RootLayout