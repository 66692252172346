import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import HeadingText from '../components/HeadingText';
import { useGetNewsQuery } from '../features/api/newsApi';
import Error from '../pages/Error';
import { getEmbedUrl } from '../Utils/getEmbedUrl';

const Section7 = () => {
  const navigate = useNavigate();
  const { data: news = [], error: newsError, isLoading: newsLoading } = useGetNewsQuery();

  if (newsLoading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError) {
    return <Error />;
  }

  const activeNews = news.filter((item) => item.is_active);

  const sortedNews = activeNews.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );

  const recentNews = sortedNews.filter((newsItem) => {
    const now = new Date();
    const updatedAt = new Date(newsItem.updated_at);
    const oneDay = 24 * 60 * 60 * 1000;

    return now - updatedAt < oneDay;
  });

  return (
    <div className="w-full px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5] py-5 my-10">
      <HeadingText title={'ভিডিও গ্যালারি'} redirect={false} />
      <div className="w-full grid lg:grid-cols-[38%_60%] grid-cols-1 md:grid-cols-2 row-auto items-start lg:gap-[2%] gap-5 md:gap-10 relative mt-10">
        {recentNews.slice(0, 1).map((newsItem) => (
          <div
            key={newsItem.id}
            className="w-full h-[320px] relative bg-black flex items-center justify-center overflow-hidden rounded-md md:col-span-3 lg:col-auto"
          >
            <iframe
              width="560"
              height="500"
              src={getEmbedUrl(newsItem.video)}
              title="Video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="w-full h-full md:h-[300px] lg:h-auto"
            ></iframe>
            <div
              className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center cursor-pointer"
              onClick={() =>
                navigate(
                  `/${newsItem.category_name}/video/${newsItem.id}`
                )
              }
            >
              <FaPlay className="text-white lg:text-6xl text-3xl" />
            </div>
          </div>
        ))}
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 row-auto items-stretch gap-5 md:col-span-3 lg:col-auto">
          {recentNews.slice(1, 7).map((newsItem) => (
            <div
              key={newsItem.id}
              className="w-full h-full relative bg-black flex items-end justify-center overflow-hidden rounded-md"
            >
              <iframe
                width="560"
                height="500"
                src={getEmbedUrl(newsItem.video)}
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="w-full h-full md:h-[250px] lg:h-auto"
              ></iframe>
              <div
                className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center cursor-pointer"
                onClick={() =>
                  navigate(
                    `/${newsItem.category_name}/video/${newsItem.id}`
                  )
                }
              >
                <FaPlay className="text-white text-3xl" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section7;