import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoMdArrowDropright } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from '../components/Banner';
import MetaTags from '../components/MetaTags';
import NewsCard from '../components/NewsCard';
import { useGetAdQuery } from '../features/api/adApi';
import { useGetBannerQuery } from '../features/api/bannerApi';
import { useGetNewsQuery } from '../features/api/newsApi';
import { useGetTrendingsQuery } from '../features/api/trendingsApi';
import useCategoryId from '../hooks/useCategoryId';
import useSortedNews from '../hooks/useSortedNews';
import Error from './Error';

const NewsByCatOrTrenOrSearch = () => {
  const navigate= useNavigate()
  const { category, tag, query, division, district, upazila } = useParams();
  const {data:tags = [], isLoading: tagsLoading,error: tagsError} = useGetTrendingsQuery()
  const [filteredNews, setFilteredNews] = useState([])
  const [tagNews, setTagNews] = useState([])
  const [loading,setLoading] = useState(true)
  const { 
    shareCountSortNews = [], 
    dateSortedNews = [],
  } = useSortedNews({category,query});

  const {data: news =[],isLoading: newsLoading, 
    error: newsError } = useGetNewsQuery()
    const { categoriesId } = useCategoryId();
  const [tagsName, setTagsName] = useState({});

  const { data: ad, isLoading: adLoading, error: adError } = useGetBannerQuery();
  const { data: ads, isLoading: adsLoading, error: adsError } = useGetAdQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const numberOfPages = Math.ceil(filteredNews.length / itemsPerPage);
  const activeNews = news.filter(item => item.is_active);

  useEffect(() => {
    let filtered = [];
  
    if (category) {
      setLoading(true)
      filtered = news.filter((newsItem) => newsItem.category_name === category);
      setLoading(false)
    } else if (tag) {
      setLoading(true)
      filtered = [...tagNews];
      setLoading(false)
    } else if (query) {
      setLoading(true)
      const queryWords = query.toLowerCase().split(' ');
      filtered = news.filter((newsItem) =>
        queryWords.some(
          (word) =>
            newsItem.headline.toLowerCase().includes(word) ||
            newsItem.content.toLowerCase().includes(word)
        )
      );
      setLoading(false)
    }
    if (division || district || upazila) {
      setLoading(true)
      const locationFilteredNews = news.filter((newsItem) => {
        const divisionMatch = division ? newsItem.division === division : true;
        const districtMatch = district ? newsItem.district === district : true;
        const upazilaMatch = upazila ? newsItem.upazila === upazila : true;
        return divisionMatch && districtMatch && upazilaMatch;
      });
  
      filtered = [...locationFilteredNews];
      setLoading(false)
    }
    setFilteredNews(filtered);
    setCurrentPage(1); 
  }, [news, category, district, division, query, tag, tagNews, upazila]);
  

  useEffect(() => {
    if (tags?.length > 0) {
      const tagMapping = tags.reduce((acc, tag) => {
        acc[tag.tag] = tag.id; 
        return acc;
      }, {});
      setTagsName(tagMapping);
    }
  }, [tags]);

  useEffect(() => {
    if (!tagsLoading && tag && activeNews.length && Object.keys(tagsName).length > 0) {
      setLoading(true)
      const tagIds = tag
        .split(',')
        .map((t) => tagsName[t.trim()]) 
        .filter((t) => t !== undefined);

      const filteredNews = news.filter((newsItem) => {
        const hasTag = newsItem.trending_tags.some((tagId) => tagIds.includes(tagId));
        return hasTag;
      });

      setTagNews(filteredNews);
      setLoading(false)
    }
  }, [news, tag, tagsLoading, tagsName,activeNews]);

  if (newsLoading || adLoading || tagsLoading || adsLoading || loading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    );
  }

  if (newsError || adError || tagsError || adsError) {
    return <Error />;
  }

  const displayedNews = filteredNews.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNavigate = (catId,newsId) => {
   return navigate(`/${categoriesId[catId]}/news/${newsId}`)
  }

  const handlePageChange = (pageNumber) => {
    setLoading(true)
    setCurrentPage(pageNumber);
    setLoading(false)
  };

  return (
   <div className='w-full  px-5 lg:px-10 xl:px-20 2xl:px-40 4k:px-96 bg-[#F5F5F5] py-10'>
    {
      tag && <MetaTags title={`${tag} - Kalerpotro.com`} description={'Explore news based on trending todays tags'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/tag/${tag}`}/>
    }
    {
      category && <MetaTags title={`${category} - Kalerpotro.com`} description={'Explore news based on category'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/category/${category}`}/>
    }
     {
      query && <MetaTags title={`${query} - Kalerpotro.com`} description={'Explore news based on search query'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/search/${category}`}/>
    }
    {
      division && !district && !upazila && <MetaTags title={`${division} - Kalerpotro.com`} description={'Explore news based on division'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/bangladesh/division_news/${division}`}/>
    }
    {
      division && district && !upazila && <MetaTags title={`${division} - ${district} - Kalerpotro.com`} description={'Explore news based on district'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/bangladesh/district_news/${division}/${district}`}/>
    }
    {
      division && district && upazila && <MetaTags title={`${division} - ${district} - ${upazila} - Kalerpotro.com`} description={'Explore news based on ypazila'} image={process.env.REACT_APP_SEO_IMAGE} url={`${process.env.REACT_APP_CLIENT_URL}/bangladesh/upazila_news/${division}/${district}/${upazila}`}/>
    }
       <div className='w-full flex items-center justify-center'>
   {
  (query || division || district || upazila) && ad
    .filter((ad) => ad.page === 'SEARCH_RESULT')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={'image'} closable={true}/>
    ))
}
{
  tag && ad
    .filter((ad) => ad.page === 'TRENDINGTAGS')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={'image'} closable={true}/>
    ))
}
{
  category && ad
    .filter((ad) => ad.page === 'CATEGORY')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={'image'}closable={true} />
    ))
}

    </div>
    <div className='w-full grid lg:grid-cols-[70%_25%] grid-cols-1 row-auto items-start lg:gap-[5%] gap-5 '>
     <div className="">
      {category && (
        <div className="flex items-center gap-2 mb-10">
        <h1 className="text-xl font-bold text-gray-500">Category</h1>
        <IoMdArrowDropright className="text-red-600 text-2xl" />
        <h1 className="text-xl font-bold text-red-600">{category}</h1>
      </div>
      )}
      {tag && (
        <div className="flex items-center gap-2 mb-10">
        <h1 className="text-xl font-bold text-gray-500">Trending Tag</h1>
        <IoMdArrowDropright className="text-red-600 text-2xl" />
        <h1 className="text-xl font-bold text-red-600">{tag}</h1>
      </div>
      )}
      {query && (
        <div className="flex items-center gap-2 mb-10">
        <h1 className="text-xl font-bold text-gray-500">Search Result</h1>
        <IoMdArrowDropright className="text-red-600 text-2xl" />
        <h1 className="text-xl font-bold text-red-600">{query}</h1>
      </div>
      )}
      {division && district && upazila && (
        <div className="flex flex-col items-start mb-10 gap-2">
          <div className="flex items-center gap-2">
            <h1 className="text-xl font-bold text-gray-500">{division}</h1>
            <IoMdArrowDropright className="text-red-600 text-2xl" />
            <h1 className="text-xl font-bold text-gray-500">{district}</h1>
          </div>
          <h1 className="text-3xl font-bold text-red-600">{upazila}</h1>
        </div>
      )}
      {division && district && !upazila &&(
        <div className="flex items-center gap-2 mb-10">
        <h1 className="text-xl font-bold text-gray-500">{division}</h1>
        <IoMdArrowDropright className="text-red-600 text-2xl" />
        <h1 className="text-3xl font-bold text-red-600">{district}</h1>
      </div>
      )}
      {division && !district && !upazila && (
        <div className="flex items-center gap-2 mb-10">
        <IoMdArrowDropright className="text-red-600 text-2xl" />
        <h1 className="text-3xl font-bold text-red-600">{division}</h1>
      </div>
      )}
      {filteredNews.length > 0 ? (
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
          {displayedNews.map((newsItem) => (
            <NewsCard key={newsItem.id} news={newsItem} />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <div className="bg-gray-100 rounded-lg p-8">
            <p className="text-xl text-gray-500">No results found</p>
          </div>
        </div>
      )}
      {filteredNews.length > 0 && (
        <>
        {/* Pagination Component */}
<div className="flex justify-center mt-5">
  <button
    className={`px-3 py-1 mx-1 border rounded ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    <IoIosArrowBack className="text-xl" />
  </button>

  {Array.from({ length: numberOfPages }, (_, index) => (
    <button
      key={index}
      className={`px-3 py-1 mx-1 border rounded ${currentPage === index + 1 ? 'bg-red-600 text-white' : 'hover:bg-gray-200'}`}
      onClick={() => handlePageChange(index + 1)}
    >
      {index + 1}
    </button>
  ))}

  <button
    className={`px-3 py-1 mx-1 border rounded ${currentPage === numberOfPages ? 'cursor-not-allowed opacity-50' : ''}`}
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === numberOfPages}
  >
    <IoIosArrowForward className="text-xl" />
  </button>
</div>

        </>
      )}
    </div>
    <div className='w-full flex flex-col items-start gap-4'>
    <div className='w-full mt-5 bg-white p-3'>
              <div className='bg-red-600 text-white font-bold text-lg py-3 rounded-md flex items-center justify-center'>
              সর্বশেষ
              </div>
              <div className='w-full grid grid-cols-1 row-auto items-center h-[300px] overflow-y-auto gap-y-3 scroll mt-5'>
                {dateSortedNews.slice(0, 5).map(newsItem => (
                  <div onClick={() => handleNavigate(newsItem.category,newsItem.id)} key={newsItem.id} className='w-full flex items-start gap-3 cursor-pointer'>
                    <img
                      src={`${newsItem.image}`}
                      alt="news.png"
                      loading='lazy'
                      className='w-[120px] object-cover h-[80px] overflow-hidden rounded-md'
                    />
                    <h1 className='text-sm font-medium w-full'>
                      {newsItem.headline}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
            <div className='w-full mt-5 bg-white p-3'>
              <div className='bg-red-600 text-white font-bold text-lg py-3 rounded-md flex items-center justify-center'>
                পাঠকপ্রিয়
              </div>
              <div className='w-full grid grid-cols-1 row-auto items-center h-[300px] overflow-y-auto gap-y-3 scroll mt-5'>
                {shareCountSortNews.slice(0, 5).map(newsItem => (
                  <div onClick={() => navigate(`/${category}/news/${newsItem.id}`)} key={newsItem.id} className='w-full flex items-start gap-3 cursor-pointer'>
                    <img
                      src={`${newsItem.image}`}
                      alt="news.png"
                      loading='lazy'
                      className='w-[120px] object-cover h-[80px] overflow-hidden rounded-md'
                    />
                    <h1 className='text-sm font-medium w-full'>
                      {newsItem.headline}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
    </div>

   </div>
   <div className='w-full flex items-center justify-center'>
   {
  (query || division || district || upazila) && ads
    .filter((ad) => ad.page === 'SEARCH_RESULT')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
    ))
}
{
  tag && ads
    .filter((ad) => ad.page === 'TRENDINGTAGS')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
    ))
}
{
  category && ads
    .filter((ad) => ad.page === 'CATEGORY')
    .sort((a, b) => a.order - b.order)
    .map((ad) => (
      <Banner key={ad.id} image={ad.image} link={ad.link} height={ad.height} width={ad.width} type={ad.file_type} closable={true}/>
    ))
}

    </div>
   </div>
  );
};

export default NewsByCatOrTrenOrSearch;