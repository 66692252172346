import './index.css';
import { RouterProvider } from 'react-router-dom';
import Routes from './routes/Routes';
import { Provider } from 'react-redux';
import store from './app/store';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
   <Provider store={store}>
     <RouterProvider router={Routes}/>
     <Toaster />
   </Provider>
    </HelmetProvider>
  );
}

export default App;
