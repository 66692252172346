import React, { useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useGetLogoQuery } from '../features/api/logoApi';
import { useGetNavLinksQuery } from '../features/api/navbarApi';
import { useGetTrendingsQuery } from '../features/api/trendingsApi';
import Error from '../pages/Error';

const Navbar = () => {
    const navigate = useNavigate()
    const [searchBox, setSearchBox] = useState(false)
    const { data: trendings, error: trendingError, isLoading: trendingLoading } = useGetTrendingsQuery();
    const { data: navlinks, error: navlinksError, isLoading: navlinksLoading } = useGetNavLinksQuery();
    const {data:logos, isLoading:logoLoading, error: logoError} = useGetLogoQuery()

    const dateToday = new Intl.DateTimeFormat("bn", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
        timeZone: "Asia/Dhaka"
    }).format(new Date());

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const form = e.target;
            const query = form.search.value;
            navigate(`/search/${query}`)
            setSearchBox(false)
        } catch (error) {
            console.log(error.message)
        }
    }

    if (trendingLoading || navlinksLoading || logoLoading) {
        return (
            <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
                <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
            </div>
        )
    }

    if (navlinksError || trendingError || logoError) {
        return <Error />
    }

    return (
        <nav className='w-full'>
<div className='px-3 lg:px-16 xl:px-20 2xl:px-40 4k:px-96 py-5  '>
<div className='lg:flex md:flex hidden items-center justify-between '>
                <div className='font-bold lg:text-lg text-xs font-fancy text-red-600'>
                    {dateToday}
                </div>
                <Link to={'/'}>
                    <div className='lg:flex hidden md:flex items-center justify-center'>
                        {logos && logos?.filter(logo => logo.portal_type === 'NEWSPORTAL' && logo.is_active === true)?.map(logo => <img src={logo.image} width={logo.width} height={logo.height} className={`object-cover`}/>)}
                    </div>
                </Link>
                <div className='flex items-center gap-3'>
                    <button onClick={()=>navigate('/latest')} className='bg-red-600 rounded-md text-white font-medium lg:px-4 px-2 w-full py-2 lg:text-base text-xs font-fancy'>
                        আজকের খবর
                    </button>
                </div>
            </div>
            <Link to={'/'}>
                    <div className='flex lg:hidden md:hidden items-center justify-center'>
                        {logos && logos?.filter(logo => logo.portal_type === 'NEWSPORTAL' && logo.is_active === true)?.map(logo => <img src={logo.image} width={logo.width} height={logo.height} className={`object-cover`}/>)}
                    </div>
                </Link>
            <div className='flex lg:hidden md:hidden flex-row gap-3 items-center justify-between mt-5'>
            <div className='font-bold lg:text-lg text-lg font-fancy text-red-600'>
                    {dateToday}
                </div>
                <div className='flex items-center lg:gap-3 gap-2'>
                    <button onClick={()=>navigate('/latest')} className='bg-red-600 rounded-md text-white font-medium lg:px-4 px-2 w-full py-2 lg:text-base text-sm font-fancy'>
                        আজকের খবর
                    </button>
                </div>
            </div>
</div>
            <div className={`w-full flex justify-between bg-[#F5F5F5] px-3 lg:px-16 xl:px-20 2xl:px-40 4k:px-96 py-5 ${searchBox ? 'flex-col items-center gap-5': 'flex-row items-center'}`}>
                {
                    searchBox && <div className={`${searchBox ? 'order-last' : ''} w-full flex items-center lg:gap-5 gap-2`}>
                        <form onSubmit={handleSearch} className='w-full flex items-center gap-3'>
                            <div className='w-full border border-red-200 rounded-md px-5 py-3'>
                                <input name='search' type="text" className='w-full focus:outline-none bg-transparent placeholder:text-red-400' placeholder='অনুসন্ধান করুন' />
                            </div>
                            <button className='p-3 rounded-md border border-red-200'>
                                <IoIosSearch className='text-lg' />
                            </button>
                        </form>
                        <button onClick={() => setSearchBox(false)} className='p-3 rounded-md border border-red-200 text-red-600'>
                            <IoClose className='text-lg' />
                        </button>
                    </div> 
                }
                 <ul className={`${searchBox ? 'order-first lg:justify-center md:justify-center justify-start': ''} flex items-center gap-10 overflow-auto w-full trending`}>
                            {
                                navlinks && navlinks.filter(menu => menu.navbar_type === 'CATEGORY').map(menu => (
                                    <NavLink
                                        key={menu.id}
                                        className={({ isActive }) =>
                                            isActive
                                                ? "text-red-500 font-bold"
                                                : "text-black font-medium"
                                        }
                                        to={`/category/${menu.name}`}
                                    >
                                        <li className=" lg:text-base text-sm cursor-pointer">{menu.name}</li>
                                    </NavLink>
                                ))
                            }
                            {
                                navlinks && navlinks.filter(menu => menu.navbar_type === 'CUSTOM').map(menu => (
                                    <NavLink
                                        key={menu.id}
                                        className={({ isActive }) =>
                                            isActive
                                                ? "text-red-500 font-bold"
                                                : "text-black font-medium"
                                        }
                                        to={menu.url}
                                    >
                                        <li className=" lg:text-base text-sm cursor-pointer">{menu.name}</li>
                                    </NavLink>
                                ))
                            }
                        </ul>
                        {
                         !searchBox &&   <button onClick={() => setSearchBox(true)} className='lg:p-3 p-2 rounded-md border border-red-200 ml-3'>
                            <IoIosSearch className='text-lg' />
                        </button>
                        }
            </div>

            <ul className='flex items-center gap-3 overflow-x-auto lg:justify-center justify-start md:justify-center trending lg:px-16 xl:px-20 2xl:px-40 4k:px-96 py-5  px-3'>
    <h1 className='lg:text-base text-sm font-bold'>ট্রেন্ডিং: </h1>
    {trendings &&
    trendings
        .filter(trending => trending.is_latest === true)
      .map((trending) => (
        <Link to={`/tag/${trending.tag}`} key={trending.id}>
          <li className='lg:text-base text-sm rounded-md border border-red-200 text-red-600 font-medium lg:px-4 px-2 lg:py-2 py-2 cursor-pointer whitespace-nowrap flex-shrink-0'>
            {trending.tag}
          </li>
        </Link>
      ))}
  </ul>
        </nav>
    )
}

export default Navbar