import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const logoApi = createApi({
  reducerPath: 'logoApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
  endpoints: (builder) => ({
    getLogo: builder.query({
      query: () => '/api/ad/logo/list/',
    }),
  }),
});

export const { useGetLogoQuery } = logoApi;
